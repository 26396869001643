<template>
  <app-modal title="Rating reconciliation" @submit="onSubmit">
    <app-form-input-text
      v-model="email"
      placeholder="Search for survey participant email"
    >
      <template #append-inner>
        <v-btn
          color="primary"
          :disabled="!email"
          size="x-small"
          type="button"
          variant="tonal"
          @click="onSearch"
          >Search
        </v-btn>
      </template>
    </app-form-input-text>

    <v-card v-if="rating" subtitle="Rating found">
      <v-card-text>
        <pre>{{ rating }}</pre>
      </v-card-text>
    </v-card>
  </app-modal>
</template>

<script lang="ts" setup>
import type { RatingEvaluationFields } from "~~/model/rating.model";

const properties = defineProps<{
  session: DatabaseTable<"course_sessions">;
}>();

const email = ref("");
const rating = ref<RatingEvaluationFields | null>(null);

async function onSearch() {
  rating.value = await $fetch(
    `/api/courses/${properties.session.course_id}/sessions/${properties.session.id}/forms/satisfaction/reply/${email.value}`,
  );
}

const { close } = useModal("ratingReconciliationModal");
async function onSubmit() {
  return close({ confirmed: true, payload: rating.value });
}
</script>
